import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sidebar',
    template: `
        <ul class="layout-menu">
            <li app-sideMenuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `,
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    model: any[];

    ngOnInit() {
        
        this.model = [
                        {
                            label:'TechDogs Admin',
                            icon:'pi pi-fw pi-file',
                            items:[
                                    {
                                        label:'Dashboard',
                                        icon:'pi pi-fw pi-home',
                                        items:[
                                            {
                                                label:'Overall',
                                                icon:'pi pi-fw pi-home',
                                                url:'Modules/overall-dashboard'
                                             },
                                             {
                                                label:'User Specific',
                                                icon:'pi pi-fw pi-user',
                                                url:'Modules/user-specific-dashboard'
                                             },
                                        ]
                                    },
                                    {
                                        label:'Portal User Management',
                                        icon:'pi pi-fw pi-user',
                                        items:[
                                           {
                                              label:'Manage Users',
                                              icon:'pi pi-fw pi-users',
                                              url:'Modules/portal-user-listing-page'

                                            //   items:[
                                            //      {
                                            //         label:'Manage User',
                                            //         icon:'pi pi-fw pi-list',
                                            //      }
                                            //   ]
                                           },
                                           {
                                            label:'Manage Roles',
                                            icon:'pi pi-fw pi-id-card',
                                            url:'Modules/portal-user-role-listing'

                                            // items:[
                                            //     {
                                            //         label:'Manage Role',
                                            //         icon:'pi pi-fw pi-list',
                                            //     }
                                            // ]
                                         },
                                           
                                        ]
                                     }, 
                                     {
                                        label:'Email Tickets',
                                        icon:'pi pi-fw pi-envelope',
                                        items:[
                                           {
                                              label:'Content Hosting',
                                              icon:'pi pi-fw pi-upload',
                                              url:'Modules/email-content-hosting' 
                                           },
                                           {
                                              label:'Site Queries',
                                              icon:'pi pi-fw pi-question-circle',
                                              url:'Modules/email-site-queries' 
                                           },
                                           {
                                              label:'Account Issues / Support',
                                              icon:'pi pi-fw pi-comments',
                                              url:'Modules/email-account-support' 
                                        },
                                        ]
                                     },
                                     {
                                        label:'Chat Support',
                                        icon:'pi pi-fw pi-comment',
                                        items:[
                                           {
                                              label:'Content Hosting',
                                              icon:'pi pi-fw pi-upload',
                                              url:'Modules/chat-content-hosting' 
                                           },
                                           {
                                                label:'Site Queries',
                                                icon:'pi pi-fw pi-question-circle',
                                                url:'Modules/chat-site-queries' 
                                           },
                                           {
                                                label:'Account Issues / Support',
                                                icon:'pi pi-fw pi-comments',
                                                url:'Modules/chat-account-support' 
                                           },
                                           {
                                                label:'Sales / Become a Client',
                                                icon:'pi pi-fw pi-user-plus',
                                                url:'Modules/chat-sales-become-a-client' 
                                           },
                                        ]
                                     },
                                     {
                                        label:'Content Moderation',
                                        icon:'pi pi-fw pi-book',
                                        items:[
                                                {
                                                    label:'Assets Comment Area',
                                                    icon:'pi pi-fw pi-list',
                                                },
                                                {
                                                    label:'Exception Reporting',
                                                    icon:'pi pi-fw pi-file',
                                                    items:[
                                                            {
                                                                label:'Moderation of Topics',
                                                                icon:'pi pi-fw pi-file',
                                                            },
                                                            {
                                                                label:'Discussion Forum',
                                                                icon:'pi pi-fw pi-comments',
                                                            },
                                                            {
                                                                label:'Network Chat',
                                                                icon:'pi pi-fw pi-comments',
                                                            },
                                    
                                                          ]
                                                },
                                                {
                                                    label:'Q & A',
                                                    icon:'pi pi-fw pi-question-circle',
                                                    items:[
                                                            {
                                                                label:'Moderation of Ques',
                                                                icon:'pi pi-fw pi-question',
                                                            },
                                                            {
                                                                label:'Answer to Ques',
                                                                icon:'pi pi-fw pi-arrow-right',
                                                            },
                                                          ]
                                                },
                                                {
                                                    label:'Blog Approval',
                                                    icon:'pi pi-fw pi-check-circle',
                                                    items:[
                                                            {
                                                                label:'Approval Process',
                                                                icon:'pi pi-fw pi-check',
                                                            },
                                                          ]
                                                },
                                                {
                                                    label:'Packs',
                                                    icon:'pi pi-fw pi-users',
                                                    items:[
                                                            {
                                                                label:'Content Posts',
                                                                icon:'pi pi-fw pi-file',
                                                            },
                                                          ]
                                                },
                                                {
                                                    label:'Bad Words',
                                                    icon:'pi pi-fw pi-ban',
                                                    items:[
                                                            {
                                                                label:'Bad Words Moderation',
                                                                icon:'pi pi-fw pi-times-circle',
                                                            },
                                                          ]
                                                },
                                              ]
                                    
                                              
                                     },
                                     {
                                        label:'Auto Response Admin',
                                        icon:'pi pi-fw pi-reply',
                                        items:[
                                            {
                                                label:'Automated Emails	',
                                                icon:'pi pi-fw pi-envelope',
                                            },
                                            {
                                                label:'Auto Chat Messages',
                                                icon:'pi pi-fw pi-comment',
                                            },
                                            {
                                                label:'Auto Case Escalation',
                                                icon:'pi pi-fw pi-chart-line',
                                            },
                                        ]
                                    },
                                    {
                                        label:'Business Registrations',
                                        icon:'pi pi-fw pi-briefcase',
                                        items:[
                                            {
                                                label:'Business Registration',
                                                icon:'pi pi-fw pi-briefcase',
                                            },
                                            {
                                                label:'Subscriber Registration',
                                                icon:'pi pi-fw pi-user-plus',
                                            },
                                            {
                                                label:'Buisness URL',
                                                icon:'pi pi-fw pi-globe',
                                                items:[
                                                    {
                                                        label:'Custom Dashboard',
                                                        icon:'pi pi-fw pi-credit-card',
                                                    },
                                                    {
                                                        label:'Analytics Customization',
                                                        icon:'pi pi-fw pi-chart-bar',
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        label:'Configuration',
                                        icon:'pi pi-fw pi-cog',
                                        items:[
                                            {
                                                label:'Email Configuration',
                                                icon:'pi pi-fw pi-envelope',
                                            },
                                            {
                                                label:'Workflow',
                                                icon:'pi pi-fw pi-sitemap',
                                            },
                                            {
                                                label:'Modules and Roles',
                                                icon:'pi pi-fw pi-id-card',
                                            },
                                        ]
                                    },
                                    {
                                        label:'Network Users',
                                        icon:'pi pi-fw pi-sitemap',
                                        items:[
                                            {
                                                label:'Users',
                                                icon:'pi pi-fw pi-user',
                                                url:'Modules/network-users'
                                            },
                                            {
                                                label:'Packs',
                                                icon:'pi pi-fw pi-users',
                                                url:'Modules/network-packs'
                                            },
                                        ]
                                    }   
                                ]
                        }
                    ];
    }

}
