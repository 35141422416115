import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/user/user.module').then(
                        (m) => m.UserModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/overall-dashboard/overall-dashboard.module').then(
                        (m) => m.OverallDashboardModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/user-specific-dashboard/user-specific-dashboard.module').then(
                        (m) => m.UserSpecificDashboardModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/portal-user-listing-page/portal-user-listing-page.module').then(
                        (m) => m.PortalUserListingPageModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/portal-user-role-listing/portal-user-role-listing.module').then(
                        (m) => m.PortalUserRoleListingModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/network-users/network-users.module').then(
                        (m) => m.NetworkUsersModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/network-packs/network-packs.module').then(
                        (m) => m.NetworkPacksModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/email-content-hosting/email-content-hosting.module').then(
                        (m) => m.EmailContentHostingModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/email-site-queries/email-site-queries.module').then(
                        (m) => m.EmailSiteQueriesModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/email-account-support/email-account-support.module').then(
                        (m) => m.EmailAccountSupportModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/chat-content-hosting/chat-content-hosting.module').then(
                        (m) => m.ChatContentHostingModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/chat-site-queries/chat-site-queries.module').then(
                        (m) => m.ChatSiteQueriesModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/chat-account-support/chat-account-support.module').then(
                        (m) => m.ChatAccountSupportModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./Modules/chat-sales-become-a-client/chat-sales-become-a-client.module').then(
                        (m) => m.ChatSalesBecomeAClientModule
                    ),
            },
            {
                path: '**',
                redirectTo: 'page-not-found',
              },
        ], 
        
        { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
