import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigComponent } from './app.config.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { SharedModule } from './shared/shared.module';
// import { UserModule } from './Modules/user/user.module';
import { CoreModule } from './core/core.module';



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        // UserModule,
        CoreModule
    ],
    declarations: [
        AppComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppAccessdeniedComponent,

    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        , AppBreadcrumbService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
